.modal.globalErrorModal,
.modal.globalErrorModal .modal-dialog {
  z-index: 2000;
}

.modal-backdrop.globalErroBackdrop {
  z-index: 1900;
}
.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
