header {
  background-color: #194866;
}
header.teams {
  background-color: var(--bs-primary);
}
#nav-dropdown {
  color: lightgray;
  margin-right: 40px;
}
.topNavbarTitle {
  margin-left: 10px;
}
