table tr.priority-high td {
    background-color: var(--bs-danger-border-subtle);
}
table tr.priority-mid td {
    background-color: var(--bs-warning-border-subtle);
}
.badge.avatarlike {
    --bs-badge-padding-y: 0.50em;
    --bs-badge-padding-x: 0.50em;
    --bs-badge-border-radius: 0.50em;
}
