.nav-link {
  font-weight: 500;
  color: #333;
}
li a.nav-link {
  font-weight: 500;
  color: #333;
}

li a.nav-link svg,
li a.nav-link img {
  margin-right: 4px;
  color: #727272;
}

.nav-link.active {
  color: #2470dc;
}

li a.nav-link:hover svg,
li a.nav-link.active svg {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
