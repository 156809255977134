.wizardStep {
  display: none;
}
.wizardStep.active {
  display: block;
}

.modal.studentSyncModal,
.modal.studentSyncModal .modal-dialog {
  z-index: 2000;
}

.modal-backdrop.studentSyncBackdrop {
  z-index: 1900;
}